import { api } from "./api";
import { apiRoutes } from "utils/constants";

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../utils/constants";

const authService = {
  async refreshToken(token) {
    const res = await api.get(
      `/patient/account/tokens/refresh-token?refreshToken=${token}`
    );

    localStorage.set(ACCESS_TOKEN_KEY, {
      token: res.data.accessToken,
      expires: res.data.accessTokenExpirationDate,
    });

    localStorage.set(REFRESH_TOKEN_KEY, {
      token: res.data.refreshToken,
      expires: res.data.refreshTokenExpirationDate,
    });
  },

  logout() {
    localStorage.clear();
  },

  getAccessKey() {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (accessToken !== null) {
      return accessToken;
    }

    return null;
  },

  isLoggedIn() {
    // const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (accessToken === null) {
      return false;
    }

    // if (
    //   refreshToken !== null &&
    //   new Date(refreshToken.expires).getTime() < new Date().getTime()
    // ) {
    //   localStorage.clear();
    //   return false;
    // }

    return true;
  },

  async getMe() {
    const response = await api.get(`${apiRoutes.me}`);
    return response.data;
  },

  async getUserById(userId) {
    const response = await api.get(`/admins/${userId}`);
    return response.data;
  },
};

export default authService;
