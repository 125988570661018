import React, { useEffect, useCallback, useState, createContext } from "react";
import CustomMenu from "./Menu/CustomMenu";
import CustomBar from "./CustomBar";
import { Layout, usePermissions } from "react-admin";
import authService from "api/auth-service";

export const UserContext = createContext(null);

const CustomLayout = (props) => {
  const { user, setUser, ...restProps } = props;
  const [isLoadUser, setIsLoadUser] = useState(false);
  const { permissions: rightPermissions } = usePermissions();

  const getUser = useCallback(async () => {
    try {
      const user = await authService.getMe();
      setUser(user);
      setIsLoadUser(true);
    } catch (error) {
      console.error("Ошибка загрузки пользователя:", error);
    }
  }, [setUser]);

  useEffect(() => {
    if (rightPermissions && !isLoadUser && !user) {
      getUser();
    }
  }, [rightPermissions, isLoadUser, getUser, user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Layout {...restProps} menu={CustomMenu} appBar={CustomBar} />
    </UserContext.Provider>
  );
};

export default CustomLayout;
