import { Filter, SelectInput, usePermissions } from "react-admin";
import { Role } from "../Constant/Role";

// Filter component
export const ListFilter = (props) => {
  const { permissions } = usePermissions();

  return (
    <Filter {...props}>
      {permissions !== "ROLE_DOCTOR" && (
        <SelectInput source="role" choices={Role} alwaysOn />
      )}
    </Filter>
  );
};
