import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { chatService } from "api/chat-service";
import { Button, useRedirect, useTranslate } from "react-admin";
import { makePhotoSource } from "utils/constants";
import { fileService } from "api/file-service";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";

import { useNavigate } from "react-router-dom";

import "./style.css";
import inMemoryJwt from "Application/Auth/inMemoryJwt";
import {VoiceRecorder} from "../../../Component/Layouts/AudioRecorder/AudioRecorder";

export const ChatPage = () => {
  const { id } = useParams();
  const sendButtonRef = useRef(null)
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [user, setUser] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const t = useTranslate();
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [audioFile, setAudioFile] = useState(null)
  const [isRecording, setIsRecording] = useState(false)
  const [sendButtonWidth, setSendButtonWidth] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [previewImage, setPreviewImage] = useState(null);

  const hiddenFileInput = useRef(null);
  const messagesEndRef = useRef(null);

  const [isEditPetDescription, setIsEditPetDescription] = useState(false);
  const [isLoadingEditPet, setIsLoadingEditPet] = useState(false);

  const [petDescription, setPetDescription] = useState("");
  const [prevPetDescription, setPrevPetDescription] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const currentLoginUserId = inMemoryJwt.getId();
  const redirect = useRedirect();
  const navigate = useNavigate();
  const doctorType = inMemoryJwt.getUserType();

  useEffect(() => {
    if (sendButtonRef?.current) {
      setSendButtonWidth(sendButtonRef.current?.offsetWidth + 20)
    }
  }, [sendButtonRef?.current])

  const handleClickVariant = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollTo({
          top: messagesEndRef.current.scrollHeight + 500,
          behavior: "smooth",
        });
      }
    }, 500);
  };

  const handleGetUser = useCallback(async () => {
    try {
      const response = await chatService.getUser(id);
      setUser(response);
      if (doctorType) {
        setChatId(doctorType === 'public' ? response.publicChat.id : response?.chat)
      } else {
        setChatId(null)
      }

      if (response.pets.length) {
        setPetDescription(response.pets[0].doctorDescription ?? "");
        setPrevPetDescription(response.pets[0].doctorDescription ?? "");
      }
    } catch (error) {
      console.error("Error fetching user:", error);
      handleClickVariant(error.message, "error");
    }
  }, [id]);

  const handleGetChatMessages = useCallback(async () => {
    if (!chatId) return;
    try {
      console.log(chatId)
      const response = await chatService.getChatMessages(chatId);
      setMessages(response.items.reverse());
      setIsInitialLoad(false);
    } catch (error) {
      console.error("Error fetching messages:", error);
      handleClickVariant(error.message, "error");
    }
  }, [chatId]);

  const handleMarkChatIsRead = useCallback(async () => {
    if (chatId) {
      await chatService.updateChatRead(chatId);
    }
  }, [chatId]);

  const handleSendMessage = async () => {
    if (isRecording) return
    if (audioFile) {
      setIsLoading(true)
      try {
        console.log(audioFile)
        fileService.uploadFile({
          file: audioFile,
          folder: "admins",
          type: 'audio',
        }).then(async (res) => {
          if (res?.source) {
            await chatService.sendMessage(chatId, {
              message: res.source,
              messageType: 'voice',
            });
            await handleGetChatMessages();
            scrollToBottom();
            handleMarkChatIsRead();
          }
        }).catch((e) => {
          console.error("Error sending message:",e)
        }).finally(() => {
          setAudioFile(null);
          setIsLoading(false)
        });
      } catch (error) {
        console.error("Error sending message:", error);
        handleClickVariant(error.message, "error");
      }
    }

    if (file) {
      try {
        await chatService.sendMessage(chatId, {
          message: file.source,
          messageType: file.type,
        });
        await handleGetChatMessages();
        setFile(null);
        setFileName("");
        scrollToBottom();
        handleMarkChatIsRead();
      } catch (error) {
        console.error("Error sending message:", error);
        handleClickVariant(error.message, "error");
      }
    }

    if (!newMessage.trim() || !chatId) return;

    try {
      await chatService.sendMessage(chatId, {
        message: newMessage,
        messageType: "text",
      });
      setNewMessage("");
      await handleGetChatMessages();
      scrollToBottom();
      handleMarkChatIsRead();
    } catch (error) {
      console.error("Error sending message:", error);
      handleClickVariant(error.message, "error");
    }
  };

  const handleKeyPress = (e) => {
    if (Boolean(audioFile)) return
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const getFileType = (fileType) => {
    if (fileType.startsWith("image/")) {
      return "photo";
    } else if (fileType.startsWith("video/")) {
      return "video";
    } else if (
      fileType === "application/pdf" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return "document";
    } else if (fileType.startsWith("audio/")) {
      return "audio";
    } else {
      handleClickVariant("No support this format", "error");
      return null;
    }
  };

  const handleChangeFile = async (event) => {
    try {
      const fileType = getFileType(event.target.files[0].type);
      if (event.target.files && fileType) {
        setIsLoadingFile(true);
        setFileName(event.target.files[0].name);
        const response = await fileService.uploadFile({
          file: event.target.files[0],
          folder: "admins",
          type: fileType,
        });
        setFile(response);

        setIsLoadingFile(false);
      }
    } catch (error) {
      if (error) {
        console.log(error);
        setIsLoadingFile(false);
        handleClickVariant(error.message, "error");
      }
    }

    event.target.value = "";
  };

  const truncateString = (str) => {
    if (str.length <= 15) return str;
    return `${str.slice(0, 6)}...${str.slice(-6)}`;
  };

  const renderFile = (fileType) => {
    switch (fileType) {
      case "photo": {
        return truncateString(fileName);
      }
      case "document": {
        return truncateString(fileName);
      }

      case "video": {
        return truncateString(fileName);
      }

      case "audio":
      case "voice": {
        return truncateString(fileName);
      }
    }
  };

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleDeleteFile = () => {
    setFile(null);
  };

  const handleUpdatePetDescription = async () => {
    if (user?.pets?.length && !isLoadingEditPet) {
      setIsEditPetDescription(true);
      try {
        await chatService.updatePetDescription(user.pets[0].id, petDescription);

        setPrevPetDescription(petDescription);
        setIsEditPetDescription(false);
        setIsLoadingEditPet(false);
      } catch (error) {
        handleClickVariant(error.message, "error");
        setIsLoadingEditPet(false);
      }
    }
  };

  const handleCancelPetDescription = () => {
    if (user?.pets?.length) {
      setPetDescription(prevPetDescription);
    } else {
      setPetDescription("");
    }

    setIsEditPetDescription(false);
  };

  const handleGoBackToPatients = () => {
    navigate(-1);
  };

  useEffect(() => {
    handleGetUser();
  }, [handleGetUser]);

  useEffect(() => {
    scrollToBottom();
  }, [isInitialLoad]);

  useEffect(() => {
    if (!user) return;

    if (messages.length === 0) {
      handleGetChatMessages();
    }

    const interval = setInterval(() => {
      handleGetChatMessages();
    }, 3000);

    return () => clearInterval(interval);
  }, [user, handleGetChatMessages]);

  return (
    <>
      <div>
        <span
          className="back-button"
          onClick={handleGoBackToPatients}
          variant="text"
          size="icon"
        >
          <img
            src={process.env.PUBLIC_URL + "/back-arrow.svg"}
            alt="back-arrow"
            style={{ width: 16, height: 16 }}
          />
          {t("resources.chat.patients")}
        </span>
        <div className="chat-header">
          <div className="chat-header-left">
            <span>{user?.name}</span>
            <div>{user?.phone}</div>

            <div>
              {user?.chat?.updatedAt
                ? dayjs(user?.chat?.updatedAt).format("M/D/YYYY, HH:mm")
                : "-"}
            </div>
          </div>
          <div className="chat-header-right">
            {isEditPetDescription ? (
              <div className="edit-pet">
                <input
                  value={petDescription}
                  onChange={(e) => setPetDescription(e.target.value)}
                />
                <div className="actions">
                  <span onClick={handleUpdatePetDescription}>
                    {t("resources.chat.save")}
                  </span>
                  <span onClick={handleCancelPetDescription}>
                    {t("resources.chat.cancel")}
                  </span>
                </div>
              </div>
            ) : (
              <div className="view-pet">
                <span className={`${petDescription ? "" : "placeholder"}`}>
                  {petDescription ? petDescription : "Enter pet name"}
                </span>
                <img
                  src={process.env.PUBLIC_URL + "/edit.svg"}
                  alt="Upload"
                  onClick={() => setIsEditPetDescription(true)}
                />
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            height: "calc(100dvh - 350px)",
            overflowY: "scroll",
          }}
          ref={messagesEndRef}
        >
          {messages.length > 0 ? (
            messages.map((msg, index) => {
              return (
                <>
                  {msg.type === "text" && (
                    <div
                      key={index}
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor:
                            msg.sender === "doctor" ? "#d0e7ff" : "#f1f1f1",
                          padding: "8px",
                          borderRadius: "8px",
                          maxWidth: "100%",
                        }}
                      >
                        <strong>
                          {msg.sender === "doctor"
                            ? `${msg?.doctor?.firstName} ${msg?.doctor?.lastName}`
                            : user?.name}
                        </strong>
                        : {msg.text}
                      </div>
                    </div>
                  )}
                  {msg.type === "photo" && (
                    <div
                      key={index}
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor:
                            msg.sender === "doctor" ? "#d0e7ff" : "#f1f1f1",
                          padding: "8px",
                          borderRadius: "8px",
                          width: "min-content",
                        }}
                      >
                        <strong>
                          {msg.sender === "doctor"
                            ? `${msg?.doctor?.firstName} ${msg?.doctor?.lastName}`
                            : user?.name}
                        </strong>
                        :{" "}
                        <img
                          style={{ maxWidth: "300px" }}
                          src={makePhotoSource(msg.text)}
                          onClick={() =>
                            setPreviewImage(makePhotoSource(msg.text))
                          }
                        />
                      </div>
                    </div>
                  )}
                  {msg.type === "document" && (
                    <div
                      key={index}
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor:
                            msg.sender === "doctor" ? "#d0e7ff" : "#f1f1f1",
                          padding: "8px",
                          borderRadius: "8px",
                          maxWidth: "100%",
                        }}
                      >
                        <strong>
                          {msg.sender === "doctor"
                            ? `${msg?.doctor?.firstName} ${msg?.doctor?.lastName}`
                            : user?.name}
                        </strong>
                        :{" "}
                        <a
                          href={makePhotoSource(msg.text)}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <img
                            src={process.env.PUBLIC_URL + "/file-view.png"}
                            alt="Play Icon"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          />
                          Download File
                        </a>
                      </div>
                    </div>
                  )}
                  {msg.type === "video" && (
                    <div
                      key={index}
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor:
                            msg.sender === "doctor" ? "#d0e7ff" : "#f1f1f1",
                          padding: "8px",
                          borderRadius: "8px",
                          maxWidth: "100%",
                        }}
                      >
                        <strong>
                          {msg.sender === "doctor"
                            ? `${msg?.doctor?.firstName} ${msg?.doctor?.lastName}`
                            : user.name}
                        </strong>
                        :{" "}
                        <video
                          controls
                          style={{
                            display: "flex",
                            width: "100%",
                            maxWidth: "400px",
                            maxHeight: "400px",
                            borderRadius: "8px",
                          }}
                        >
                          <source
                            src={makePhotoSource(msg.text)}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  )}
                  {(msg.type === "voice" || msg.type === "audio") && (
                    <div
                      key={index}
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor:
                            msg.sender === "doctor" ? "#d0e7ff" : "#f1f1f1",
                          padding: "8px",
                          borderRadius: "8px",
                          maxWidth: "100%",
                        }}
                      >
                        <strong>
                          {msg.sender === "doctor"
                            ? `${msg?.doctor?.firstName} ${msg?.doctor?.lastName}`
                            : user?.name}
                        </strong>
                        :{" "}
                        <audio
                          controls
                          style={{
                            display: "flex",
                            maxWidth: "400px",
                            alignItems: "center",
                          }}
                        >
                          <source
                            src={makePhotoSource(msg.text)}
                            type="audio/mpeg"
                          />
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                    </div>
                  )}
                </>
              );
            })
          ) : (
            <p>{t("resources.chat.noMessages")}</p>
          )}
        </div>

        <div className="wrapper-send">
          <div className="upload-file">
            {isLoadingFile ? (
              <div>Loading...</div>
            ) : (
              file && <div className="file-name">{renderFile(file.type)}</div>
            )}

            {file && (
              <div>
                <Button
                  onClick={handleClick}
                  variant="text"
                  size="icon"
                  className="button-text-edit"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/edit.svg"}
                    alt="Upload"
                  />
                  {t("resources.chat.edit")}
                </Button>
                <Button
                  className="button-text-delete"
                  onClick={handleDeleteFile}
                  variant="text"
                  size="icon"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/delete.svg"}
                    alt="Upload"
                  />
                  {t("resources.chat.remove")}
                </Button>
              </div>
            )}
            {!file && (
              <Button
                size="icon"
                variant="contained"
                className="button-primary"
                onClick={handleClick}
              >
                <img
                  src={process.env.PUBLIC_URL + "/upload.svg"}
                  alt="Upload"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
                {t("resources.chat.sendFile")}
              </Button>
            )}
            <input
              accept="image/*, .pdf, .docx, video/*, audio/*"
              type="file"
              style={{ display: "none" }}
              onChange={(event) => handleChangeFile(event)}
              ref={hiddenFileInput}
            />
          </div>
          {isLoading ? <div>Loading...</div> : <div className="textarea-block">
            <textarea
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              disabled={Boolean(audioFile)}
              placeholder={audioFile ? "" : t("resources.chat.inputPlaceholder")}
              className="textarea"
            />
            <div style={{ left: Boolean(audioFile) ? '10px' : 'unset', right: Boolean(audioFile) ? 'unset' : `${sendButtonWidth}px` }} className="voice-recorder-wrap">
              <VoiceRecorder
                  audioFile={audioFile}
                  passAudio={(e) => setAudioFile(e)}
                  passIsRecording={(e) => setIsRecording(e)}
              />
            </div>
            <div ref={sendButtonRef} className="send-button-wrap">
              <Button
                  disabled={isRecording}
                  variant="contained"
                  className="button-send"
                  onClick={handleSendMessage}
              >
                <img src={`${process.env.PUBLIC_URL}/send.svg`} alt="Upload" />
              </Button>
            </div>
          </div>}
        </div>
      </div>
      {previewImage && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1200,
          }}
          onClick={() => setPreviewImage(null)}
        >
          <img
            src={previewImage}
            alt="Full Preview"
            style={{ maxWidth: "90%", maxHeight: "90%", borderRadius: "8px" }}
          />
        </div>
      )}
    </>
  );
};
