import { apiRoutes } from "utils/constants";
import { api } from "./api";

export const scheduleService = {
  async getWorkingDays(params) {
    const query = {
      sort: {
        column: params.sort.field,
        direction: params.sort.order,
      },
      direction: params.sort.order,
      size: params.pagination.perPage,
      page: params.pagination.page,
      filter: params.filter,
      //roles: "ROLE_ADMIN"
    };

    const response = await api.get(apiRoutes.schedule, JSON.stringify(query));
    return response.data;
  },

  async createWorkingDay(body) {
    const response = await api.post(apiRoutes.schedule, body);
    return response.data;
  },

  async editWorkingDay(body, id) {
    const response = await api.put(`${apiRoutes.schedule}/${id}`, body);
    return response.data;
  },

  async deleteWorkingDay(id) {
    const response = await api.delete(`${apiRoutes.schedule}/${id}`);
    return response.data;
  },
};
