import axios from "axios";
import { REFRESH_TOKEN_KEY } from "../utils/constants";

import authService from "./auth-service";
import inMemoryJwt from "../Application/Auth/inMemoryJwt";

const API_URL = process.env.REACT_APP_DOMAIN;

export const api = axios.create({
  baseURL: `${API_URL}/api/admin`,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    "api-version": "1.4",
  },
});

api.interceptors.request.use(async (config) => {
  console.log('sho eto')
  if (authService.isLoggedIn()) {
    config.headers.Authorization = `Bearer ${inMemoryJwt.getToken()}`;
  }

  return config;
});

api.interceptors.response.use(
  (res) => {
    return res.data;
  },
  async (error) => {
    if (error.response.status === 401) {
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

      if (refreshToken) {
        try {
          await inMemoryJwt.getRefreshedToken();
          document.location.reload();
        } catch (error) {
          if (axios.isAxiosError(error) && error.response) {
            authService.logout();
            window.location.href = "/#/login";
          }
        }
      } else {
        authService.logout();
        window.location.href = "/#/login";
      }
    }

    return Promise.reject(error);
  }
);
