import React from "react";
import { useRecordContext, useRedirect } from "react-admin";
import Button from "@mui/material/Button";
import { useTranslate } from "react-admin";

const ChatButton = () => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const translate = useTranslate();

  if (!record) return null;

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => redirect(`/chat/${record.id}`)}
    >
      {translate("buttons.chat")}
    </Button>
  );
};

export default ChatButton;
