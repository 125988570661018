import { useRecordContext } from "react-admin";
import { makePhotoSource } from "utils/constants";

export const PhotoField = () => {
  const record = useRecordContext();

  return record?.photo ? (
    <>
      <img
        src={makePhotoSource(record.photo.source)}
        alt="avatar"
        style={{ width: "60px", height: "60px" }}
      />
    </>
  ) : null;
};
