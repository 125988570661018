import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import events from "./events";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Api from "../../../Application/Api/api";

const localizer = momentLocalizer(moment);

export const MyCalendar = () => {
  const [eventsData, setEventsData] = useState(events);

  const handleSelect = ({ start, end }) => {
    const title = window.prompt("New Event name");
    if (title)
      setEventsData([
        ...eventsData,
        {
          start,
          end,
          title,
        },
      ]);
    Api.create("event", {
      data: {
        start: start,
        end: end,
        title: title,
      },
    }).then((response) => {
      return response;
    });
  };
  return (
    <div className="App">
      <Calendar
        views={["day", /*"agenda",*/ "work_week"]}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="work_week"
        events={eventsData}
        style={{ height: "100vh" }}
        onSelectEvent={(event) => alert(event.start)}
        onSelectSlot={handleSelect}
      />
    </div>
  );
};

/*export const MyCalendar = () => {

    return (
       /!* <ListMod {...props}
            //actions={<ListActions/>}
            title="User management"
            filters={<ListFilter/>}
        >*!/

        /!*</ListMod>*!/
    );

};*/
