import React, { useState } from "react";
import { required, minLength } from "react-admin";
import {
  TextInputMod,
  PasswordInputMod,
  SelectInputMod,
} from "Component/Layouts/Input/index";
import {
  CreateMod,
  SimpleFormMod,
  ToolBarButtonModCreate,
  ActionsMod,
} from "Component/Layouts/Form/index";
import {
  EmailValidation,
  RepeatPasswordValidation,
} from "Component/Validation/index";
import { Role } from "Pages/Admin/Constant/Role";
import { useCategoryChoices } from "../Constant/Category";
import { useTypeChoices } from "../Constant/Type";
import { useTranslate } from "react-admin";

export const AdminsCreate = (props) => {
  const translate = useTranslate();
  const [selectedRole, setSelectedRole] = useState(null);

  const categories = useCategoryChoices();
  const types = useTypeChoices();

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  return (
    <CreateMod
      actions={
        <ActionsMod title={translate("ra.action.create") + " Admin / Doctor"} />
      }
      {...props}
    >
      <SimpleFormMod
        toolbar={
          <ToolBarButtonModCreate showCancel="true" hrefCancel="/#/admins" />
        }
        encType="multipart/form-data"
      >
        <TextInputMod
          fullWidth
          label={translate("adminsForm.fields.firstName")}
          source="firstName"
          validate={required()}
        />
        <TextInputMod
          fullWidth
          label={translate("adminsForm.fields.lastName")}
          source="lastName"
          validate={required()}
        />
        <SelectInputMod
          fullWidth
          label={translate("adminsForm.fields.role")}
          source="role"
          id="adminRole"
          validate={required()}
          choices={Role}
          onChange={handleRoleChange}
        />
        {selectedRole === "ROLE_DOCTOR" && (
          <SelectInputMod
            fullWidth
            label={translate("adminsForm.fields.type")}
            source="type"
            id="type"
            choices={types}
            validate={required()}
          />
        )}
        {selectedRole === "ROLE_DOCTOR" && (
          <SelectInputMod
            fullWidth
            label={translate("adminsForm.fields.category")}
            source="category"
            id="category"
            choices={categories}
            validate={required()}
          />
        )}
        {selectedRole === "ROLE_DOCTOR" && (
          <TextInputMod
            fullWidth
            label={translate("adminsForm.fields.phone")}
            source="phone"
            id="phone"
            validate={required()}
          />
        )}
        <TextInputMod
          fullWidth
          label={translate("adminsForm.fields.email")}
          source="email"
          validate={[required(), EmailValidation]}
        />
        <TextInputMod
          fullWidth
          label={translate("adminsForm.fields.description")}
          source="description"
          validate={[]}
        />
        <PasswordInputMod
          fullWidth
          label={translate("adminsForm.fields.password")}
          source="password"
          validate={[required(), minLength(8)]}
        />
        <PasswordInputMod
          fullWidth
          label={translate("adminsForm.fields.passwordRepeat")}
          source="passwordRepeat"
          validate={[required(), RepeatPasswordValidation, minLength(8)]}
        />
      </SimpleFormMod>
    </CreateMod>
  );
};
