import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  useRecordContext,
  useNotify,
  useTranslate,
  useGetList,
} from "react-admin";
import { useMemo } from "react";

export const DoctorsOnDutyCreate = () => {
  const notify = useNotify();
  const translate = useTranslate();

  const { data: doctors } = useGetList("admins", {
    filter: { status: "active", role: "ROLE_DOCTOR" },
    pagination: { page: 1, perPage: 50 },
    sort: { field: "lastName", order: "ASC" },
  });

  const defaultDoctorId = useMemo(() => {
    if (!doctors) return null;
    const onDutyDoctor = doctors.find((doctor) => doctor.isOnDuty);
    return onDutyDoctor ? onDutyDoctor.id : null;
  }, [doctors]);

  const onSuccess = (data) => {
    notify(`Changes saved`);
    window.location.reload();
  };

  return (
    <Create mutationOptions={{ onSuccess }}>
      <SimpleForm>
        <ReferenceInput
          source={translate("resources.doctors.name")}
          reference="admins"
          alwaysOn
          filter={{ status: "active", role: "ROLE_DOCTOR" }}
        >
          <SelectInput
            optionText={<FullNameField />}
            alwaysOn
            defaultValue={defaultDoctorId}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

const FullNameField = () => {
  const record = useRecordContext();
  return record ? (
    <span>
      {record.firstName} {record.lastName}
    </span>
  ) : null;
};
