import React from "react";

const Logo = (props) => {
  const { className } = props;

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 69 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M53.6695 44.8052C53.3795 48.6346 50.947 52 44.9433 52C38.3815 52 38.1368 48.7951 33.5647 48.7951C28.9927 48.7951 28.7479 52 22.1862 52C16.1825 52 13.7507 48.6353 13.46 44.8052C13.0837 39.8434 17.0136 37.2907 19.1051 35.707C20.7425 34.4671 22.6261 32.8947 23.6545 30.1334C25.4136 25.4099 28.2783 21.7411 33.5647 21.7411C38.8512 21.7411 41.7158 25.4099 43.4749 30.1334C44.5034 32.8947 46.3869 34.4671 48.0244 35.707C50.1159 37.2914 54.0457 39.8434 53.6695 44.8052Z"
        fill="#63BDA9"
      />
      <path
        d="M41.2015 3.62105C44.0455 0.189443 53.1704 4.91718 51.941 7.96005C51.0009 10.2868 47.3819 6.36086 46.5984 8.30002C45.9546 9.8934 48.6757 9.34261 47.9508 11.1367C47.2259 12.9308 45.565 10.8577 45.0074 12.2378C44.2015 14.2326 49.2208 14.6927 48.3974 16.7306C47.2404 19.5944 37.7539 15.7616 37.658 12.3916C37.5757 9.49703 39.4726 5.7073 41.2015 3.62105Z"
        fill="#63BDA9"
      />
      <path
        d="M15.8273 25.3025C8.82061 32.3091 -0.974217 20.9224 2.6603 17.8603C6.37279 14.7324 7.83112 22.0988 10.2274 19.7026C12.6236 17.3063 5.82121 15.6517 8.24195 12.2786C11.576 7.63282 22.4187 18.7111 15.8273 25.3025Z"
        fill="#63BDA9"
      />
      <path
        d="M64.5861 14.9231C62.4657 12.8517 59.7263 10.0633 57.7523 12.0373C55.4743 14.3153 58.6632 16.8974 58.6632 16.8974C58.6632 16.8974 51.3734 22.3647 54.259 25.2502C57.7995 28.7908 62.9155 21.1497 62.9155 21.1497C62.9155 21.1497 65.6498 23.8828 67.6238 21.9088C69.5978 19.9348 66.761 17.0477 64.5861 14.9231Z"
        fill="#63BDA9"
      />
      <path
        d="M21.8237 3.71251C17.6106 7.43219 22.6239 18.7086 27.1446 16.8822C30.0929 15.691 28.1115 11.9187 28.1115 11.9187C28.1115 11.9187 33.9701 10.0082 32.0645 5.29161C30.2377 0.770198 24.6886 1.18315 21.8237 3.71251Z"
        fill="#63BDA9"
      />
      <path
        d="M25.9381 9.14984C25.0253 9.10651 24.435 7.64548 25.0615 6.98023C25.6603 6.34437 26.9922 6.66505 27.236 7.50378C27.4644 8.28986 26.7558 9.18865 25.9381 9.14984Z"
        fill="white"
      />
      <path
        d="M30.7773 36.8555C30.7773 34.5042 31.3219 30.8855 33.2774 30.8855C35.2329 30.8855 35.8284 34.3171 35.8284 36.8555C35.8284 39.3939 35.329 42.8555 33.2774 42.8555C31.2258 42.8555 30.7773 39.2068 30.7773 36.8555Z"
        fill="white"
      />
      <path
        d="M33.3171 34.345C35.6684 34.345 39.2871 34.8895 39.2871 36.845C39.2871 38.8005 35.8555 39.3961 33.3171 39.3961C30.7787 39.3961 27.3171 38.8966 27.3171 36.845C27.3171 34.7934 30.9658 34.345 33.3171 34.345Z"
        fill="white"
      />
    </svg>
  );
};

export default Logo;
