import russianMessages from "ra-language-russian";

export const ru = {
  ...russianMessages,
  menu: {
    settings: {
      name: "Настройки",
    },
    patients: {
      name: "Пациент",
    },
    doctorsOnDuty: {
      name: "Дежурные врачи",
    },
    admins: {
      name: "Админы",
    },
    doctor: {
      name: "Доктор",
    },
    users: {
      name: "Пациенты",
    },
  },
  adminsForm: {
    fields: {
      firstName: "Имя",
      lastName: "Фамилия",
      role: "Роль",
      type: "Тип",
      category: "Категория",
      phone: "Телефон",
      email: "Email",
      description: "Описание",
      password: "Пароль",
      passwordRepeat: "Повторите пароль",
      photo: "Фото",
    },
  },
  status: {
    registered: "Зарегистрированный",
    paid: "Оплачено",
  },
  categories: {
    first: "Первая",
    highest: "Высшая",
  },
  types: {
    public: "Публичный",
    private: "Частный",
  },
  actions: {
    edit: "Редактировать",
  },
  buttons: {
    changePassword: "Изменить пароль",
    chat: "Чат",
  },
  dataGrid: {
    noResults: "Результатов не найдено",
  },
  subscription: {
    statuses: {
      created: "Создана",
      active: "Активна",
      completed: "Просрочена",
      pending: "В обработке",
      canceled: "Отменена",
      notAvailable: "Недоступна",
      noSubscription: "Нет",
    },
  },
  resources: {
    chat: {
      noMessages: "Сообщений нет",
      title: "Чат с пользователем",
      inputPlaceholder: "Введите ваше сообщение",
      sendMessage: "Отправить",
      sendFile: "Загрузить",
      edit: "Редактировать",
      save: "Сохранить",
      remove: "Удалить",
      cancel: "Отмена",
      patients: "Пациенты",
    },
    photoModal: {
      title: "Редактирование фото",
      saveButton: "Сохранить",
      editButton: "Изменить",
      removeButton: "Удалить",
    },
    admins: {
      name: "Админ |||| Админы",
      fields: {
        id: "id",
        email: "Email",
        phone: "Телефон",
        role: "Роль",
        createdAt: "Дата создания",
        isOnDuty: "На дежурстве",
        createdBy: "Кем создан",
        firstName: "Имя",
        status: "Статус",
        lastName: "Фамилия",
        description: "Описание",
        photo: "Фото",
        category: "Категория",
      },
      tabs: {
        information: "Информация",
        clients: "Пациенты",
        appointments: "Назначения",
        schedule: "Расписание",
        income: "Доход",
        activity: "Активность",
      },
    },
    doctors: {
      name: "doctor",
    },
    changePassword: {
      name: "Смена пароля",
      fields: {
        password: "Пароль",
        passwordRepeat: "Повторите пароль",
      },
    },
    users: {
      name: "Пользователь |||| Пользователи",
      fields: {
        id: "id",
        email: "Email",
        type: "Тип мессенджера",
        status: "Статус",
        phone: "Телефон",
        createdAt: "Дата создания",
        updatedAt: "Дата изменения",
        createdBy: "Кем создан",
        name: "Имя",
        subscription: "Подписка",
        isBlackList: "Черный список",
        pets: "Животные",
        doctor: "Доктор",
        lastVisitDate: "Дата последнего визита",
        lastUpdatedBy: "Последнее обновление от",
        tabs: {
          information: "Информация",
        },
      },
    },
    pets: {
      name: "Животное |||| Животные",
      fields: {
        id: "id",
        name: "Имя",
        petType: "Тип животного",
        photo: "Фото",
        age: "Возраст",
        birtDate: "Дата рождения",
        breed: "Порода",
        gender: "Пол",
        weight: "Вес",
      },
    },
    appointments: {
      name: "Назначение |||| Назначения",
      fields: {
        id: "id",
        client: "Клиент",
        status: "Статус",
        date: "Дата",
        dateStart: "Начало",
        dateEnd: "Конец",
        createdAt: "Дата создания",
        updatedAt: "Дата изменения",
      },
    },
    admin_logs: {
      name: "Активность |||| Активность",
      fields: {
        id: "id",
        createdAt: "Дата создания",
        event: "Событие",
      },
    },
  },
};
