import { Menu, usePermissions } from "react-admin";
import { useLocation } from "react-router-dom";
import React from "react";
import SubMenu from "./SubMenu";
import EventNoteIcon from "@mui/icons-material/EventNote";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SettingsIcon from "@mui/icons-material/Settings";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import { useTranslate } from "react-admin";

const CustomMenu = (props) => {
  const { permissions } = usePermissions();
  const { pathname } = useLocation();
  const translate = useTranslate();

  return (
    <Menu {...props}>
      {permissions !== "ROLE_DOCTOR" && (
        <SubMenu
          primaryText={translate("menu.users.name")}
          leftIcon={<EventNoteIcon />}
          isDropdownOpen={["/users"].includes(pathname)}
        >
          <Menu.Item
            to="/users"
            primaryText={translate("menu.users.name")}
            leftIcon={<FormatListBulletedIcon />}
          />
        </SubMenu>
      )}

      <Menu.Item
        to="/admins"
        primaryText={
          permissions !== "ROLE_DOCTOR"
            ? translate("menu.admins.name")
            : translate("menu.doctor.name")
        }
        leftIcon={<AccountBoxIcon />}
      />
      {permissions !== "ROLE_DOCTOR" && (
        <SubMenu
          primaryText={translate("menu.settings.name")}
          leftIcon={<SettingsIcon />}
          isDropdownOpen={["/settings"].includes(pathname)}
        >
          <Menu.Item
            to="/doctor/on-duty"
            primaryText={translate("menu.doctorsOnDuty.name")}
            leftIcon={<LocalHospitalIcon />}
          />
        </SubMenu>
      )}
    </Menu>
  );
};

export default CustomMenu;
