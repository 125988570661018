import {
  ReferenceManyField,
  Datagrid,
  Pagination,
  useRefresh,
  useTranslate,
} from "react-admin";
import dayjs from "dayjs";
import { FunctionField } from "react-admin";
import {
  DateFieldMod,
  EmailFieldMod,
  TextFieldMod,
} from "../../../../../Component/Layouts/Field";
import ChatButton from "../../../../../Component/Layouts/Button/ChatButton";
import { NoResults } from "Component/NoResults/NoResults";
import { useEffect, useMemo } from "react";

import "./style.css";
import { useMediaQuery } from "@material-ui/core";
import inMemoryJwt from "Application/Auth/inMemoryJwt";

const ClientsTab = (props) => {
  const { currentUser } = props;
  const refresh = useRefresh();
  const translate = useTranslate();
  const isMobile = useMediaQuery("(max-width:600px)");

  const doctorType = inMemoryJwt.getUserType();
  const userRole = inMemoryJwt.getRoles();

  const subscriptionStatus = useMemo(() => {
    return {
      created: translate("subscription.statuses.created"),
      active: translate("subscription.statuses.active"),
      completed: translate("subscription.statuses.completed"),
      pending: translate("subscription.statuses.pending"),
      canceled: translate("subscription.statuses.canceled"),
      not_available: translate("subscription.statuses.notAvailable"),
    };
  }, [translate]);

  useEffect(() => {
    const interval = setInterval(() => {
      refresh();
    }, 1000);

    return () => clearInterval(interval);
  }, [refresh]);

  return (
    <ReferenceManyField
      reference="users"
      target="doctor1"
      label={false}
      pagination={<Pagination />}
      filter={{
        doctorType: userRole === "ROLE_ADMIN" ? currentUser?.type : doctorType,
      }}
      sort={{ field: "chatRead", order: "ASC" }}
    >
      <Datagrid
        bulkActionButtons={false}
        empty={<NoResults />}
        rowStyle={(record) => ({
          fontWeight: record?.chat ? (record?.chat?.isRead ? 400 : 600) : 400,
          backgroundColor: record?.chat
            ? record?.chat?.isRead
              ? "inherit"
              : "rgb(200 219 253)"
            : "inherit",
        })}
        className="clients"
      >
        {/* <TextFieldMod source="id" /> */}
        <TextFieldMod source="name" />
        {!isMobile && <TextFieldMod source="phone" />}
        {!isMobile && <EmailFieldMod source="email" />}
        {!isMobile && (
          <DateFieldMod
            locales="en-GB"
            label="resources.users.fields.lastVisitDate"
            source="chat.updatedAt"
            showTime
            options={{
              hour: "2-digit",
              minute: "2-digit",
              hourCycle: "h24",
              year: "numeric",
              month: "2-digit",
              day: "numeric",
            }}
            sortable={false}
          />
        )}
        <FunctionField
          label="resources.users.fields.lastUpdatedBy"
          render={(record) =>
            record.doctor
              ? `${record.doctor.firstName} ${record.doctor.lastName}`
              : ""
          }
        />

        {!isMobile && (
          <FunctionField
            label="resources.users.fields.subscription"
            render={(record) =>
              record.subscription
                ? record.subscription.status === "active"
                  ? `${subscriptionStatus[record.subscription.status]} (${dayjs(
                      record.subscription.startAt
                    ).format("DD/MM/YYYY")})`
                  : subscriptionStatus[record.subscription.status]
                : translate("subscription.statuses.noSubscription")
            }
          />
        )}

        <div className="end-chat-button">
          <ChatButton />
        </div>
      </Datagrid>
    </ReferenceManyField>
  );
};

export default ClientsTab;
