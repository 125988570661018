import { useTranslate } from "react-admin";

export const Category = [
  { id: "first", name: "First" },
  { id: "highest", name: "Highest" },
];

export const useCategoryChoices = () => {
  const translate = useTranslate();
  return [
    { id: "first", name: translate("categories.first") },
    { id: "highest", name: translate("categories.highest") },
  ];
};
