import React, {useEffect, useRef, useState} from 'react';
import MicRecorder from 'mic-recorder-to-mp3';
import {Button, useNotify} from "react-admin";
import {MINUTE_IN_MILLISECONDS} from "../../../utils/constants";

const recorder = new MicRecorder({ bitRate: 128 });

export const VoiceRecorder = ({ audioFile, passAudio, passIsRecording }) => {
    const [isRecording, setIsRecording] = useState(false);
    const [audioURL, setAudioURL] = useState(null);
    const [blob, setBlob] = useState(null);

    const startTimeRef = useRef(null);
    const timerRef = useRef(null);
    const notify = useNotify();

    useEffect(() => {
        if (blob && passAudio) {
            const fileName = generateFileName();
            const ogaFile = new File([blob], fileName, { type: 'audio/ogg' });
            passAudio(ogaFile);
        }
    }, [blob]);

    useEffect(() => {
        if (!audioFile) {
            passAudio(null);
            setBlob(null);
            setAudioURL(null);
        }
    }, [audioFile])

    const generateFileName = () => {
        const now = new Date();
        const pad = (n) => n.toString().padStart(2, '0');
        const dateStr = `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())}_${pad(now.getHours())}-${pad(now.getMinutes())}-${pad(now.getSeconds())}`;
        return `dev/telegram/private/${dateStr}.oga`;
    };

    const startRecording = () => {
        recorder.start().then(() => {
            setIsRecording(true)
            passIsRecording(true);

            startTimeRef.current = Date.now();

            // This method stops recording after 1 minute
            timerRef.current = setInterval(() => {
                const elapsed = Date.now() - startTimeRef.current;
                if (elapsed >= MINUTE_IN_MILLISECONDS) {
                    stopRecording();
                    notify('Audio recording stops! Reason: Audio recording limit - 1 minute.')
                }
            }, 1000);
        });
    };

    const stopRecording = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
        }

        recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                const audioUrl = URL.createObjectURL(blob);
                setAudioURL(audioUrl);
                setBlob(blob);
                setIsRecording(false);
                passIsRecording(false);
            });
    };

    const deleteAudio = () => {
        passAudio(null);
        setBlob(null);
        setAudioURL(null);
    }

    return (
        <div className={'voice-recorder'}>
            {audioURL ? (<div className="voice-recorder__audio">
                <audio controls src={audioURL} />
                <Button
                    variant="contained"
                    className={`voice-recorder-button recording`}
                    onClick={deleteAudio}
                >
                    <img src={`${process.env.PUBLIC_URL}/trash-icon.svg`} alt="Delete Audio" />
                </Button>
            </div>) : <Button
                variant="contained"
                className={`voice-recorder-button ${isRecording ? 'recording' : ''}`}
                onClick={isRecording ? stopRecording :  startRecording}
            >
                {isRecording
                    ? <img src={`${process.env.PUBLIC_URL}/square-icon.svg`} alt="Stop Recording" />
                    : <img src={`${process.env.PUBLIC_URL}/microphone.svg`} alt="Play Recording" />
                }
            </Button>}
        </div>
    );
};
