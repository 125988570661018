import { useTranslate } from "react-admin";

export const Type = [
  { id: "public", name: "Public" },
  { id: "private", name: "Private" },
];

export const useTypeChoices = () => {
  const translate = useTranslate();
  return [
    { id: "public", name: translate("types.public") },
    { id: "private", name: translate("types.private") },
  ];
};
