import * as React from "react";
import { EditButton, ShowButton, DeleteWithConfirmButton } from "react-admin";
import { Tooltip } from "@material-ui/core";
import { useTranslate } from "react-admin";

const CrudMod = (props) => {
  const translate = useTranslate();
  const { del, show, edit, children } = props;

  return (
    <div style={{ textAlign: "center" }}>
      {children ? children : ""}
      {show ? (
        <Tooltip title={translate("ra.action.show")}>
          <ShowButton t {...props} label="" />
        </Tooltip>
      ) : (
        ""
      )}
      {edit ? (
        <Tooltip title={translate("ra.action.edit")}>
          <EditButton {...props} label="" />
        </Tooltip>
      ) : (
        ""
      )}
      {del ? (
        <Tooltip title={translate("ra.action.delete")}>
          <DeleteWithConfirmButton
            confirmColor="warning"
            confirmTitle={translate("ra.action.delete")}
            confirmContent={translate("ra.message.delete_content")}
            undoable={false}
            {...props}
            label=""
          />
        </Tooltip>
      ) : (
        ""
      )}
    </div>
  );
};

export default CrudMod;
