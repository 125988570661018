import React from "react";
import { required, BooleanInput, useTranslate } from "react-admin";
import { TextInputMod, SelectInputMod } from "Component/Layouts/Input/index";
import {
  EditMod,
  SimpleFormMod,
  ToolBarButtonModEdit,
  ActionsMod,
} from "Component/Layouts/Form/index";
import { EmailValidation } from "Component/Validation/index";
import { useStatusChoices } from "../Constant/Status";

export const UsersEdit = (props) => {
  const translate = useTranslate();

  return (
    <EditMod
      actions={<ActionsMod title={`${translate("actions.edit")} User`} />}
      {...props}
      mutationMode="pessimistic"
      undoable={false}
    >
      <SimpleFormMod
        toolbar={
          <ToolBarButtonModEdit
            showCancel="true"
            // hrefCancel="/cabinet/#/users"
            hrefCancel="/#/users"
          />
        }
        variant="outlined"
        encType="multipart/form-data"
      >
        <TextInputMod fullWidth source="name" validate={required()} />
        <TextInputMod
          fullWidth
          source="email"
          validate={[required(), EmailValidation]}
        />
        {/* <TextInputMod fullWidth source="phone" validate={[required()]}/> */}
        <SelectInputMod
          fullWidth
          source="status"
          validate={required()}
          choices={useStatusChoices()}
        />
        <BooleanInput source="isBlackList" />
      </SimpleFormMod>
    </EditMod>
  );
};
