import ukrainianMessages from "ra-language-ukrainian";

export const ua = {
  ...ukrainianMessages,
  menu: {
    settings: {
      name: "Налаштування",
    },
    patients: {
      name: "Пацієнт",
    },
    doctorsOnDuty: {
      name: "Лікарі на чергуванні",
    },
    admins: {
      name: "Адміни",
    },
    doctor: {
      name: "Доктор",
    },
    users: {
      name: "Паціенти",
    },
  },
  adminsForm: {
    fields: {
      firstName: "Ім'я",
      lastName: "Прізвище",
      role: "Роль",
      type: "Тип",
      category: "Категорія",
      phone: "Телефон",
      email: "Email",
      description: "Опис",
      password: "Пароль",
      passwordRepeat: "Повторіть пароль",
      photo: "Фото",
    },
  },
  status: {
    registered: "Зареєстрований",
    paid: "Оплачено",
  },
  categories: {
    first: "Перша",
    highest: "Вища",
  },
  types: {
    public: "Публічний",
    private: "Приватний",
  },
  actions: {
    edit: "Редагувати",
  },
  buttons: {
    changePassword: "Змінити пароль",
    chat: "Чат",
  },
  dataGrid: {
    noResults: "Результатів не знайдено",
  },
  subscription: {
    statuses: {
      created: "Створена",
      active: "Активна",
      completed: "Протермінована",
      pending: "В обробці",
      canceled: "Скасована",
      notAvailable: "Не доступна",
      noSubscription: "Немає",
    },
  },
  resources: {
    chat: {
      noMessages: "Повідомлень немає",
      title: "Чат з користувачем",
      inputPlaceholder: "Введіть ваше повідомлення",
      sendMessage: "Відправити",
      sendFile: "Завантажити",
      edit: "Редагувати",
      save: "Зберегти",
      remove: "Видалити",
      cancel: "Відмінити",
      patients: "Пацієнти",
    },
    photoModal: {
      title: "Редагування фото",
      saveButton: "Зберегти",
      editButton: "Змінити",
      removeButton: "Видалити",
    },
    admins: {
      name: "Адмін |||| Адміни",
      fields: {
        id: "id",
        email: "Email",
        phone: "Телефон",
        role: "Роль",
        createdAt: "Коли створено",
        isOnDuty: "На чергуванні",
        createdBy: "Ким створено",
        firstName: "Ім'я",
        status: "Статус",
        lastName: "Прізвище",
        description: "Опис",
        photo: "Фото",
        category: "Категорія",
      },
      tabs: {
        information: "Інформація",
        clients: "Пацієнти",
        appointments: "Призначення",
        schedule: "Розклад",
        income: "Дохід",
        activity: "Активність",
      },
    },
    doctors: {
      name: "doctor",
    },
    changePassword: {
      name: "Зміна паролю",
      fields: {
        password: "Пароль",
        passwordRepeat: "Пароль ще раз",
      },
    },
    users: {
      name: "Користувач |||| Користувачі",
      fields: {
        id: "id",
        email: "Email",
        type: "Тип месенджера",
        status: "Статус",
        phone: "Телефон",
        createdAt: "Коли створено",
        updatedAt: "Коли змінено",
        createdBy: "Ким створено",
        name: "Ім'я",
        subscription: "Підписка",
        isBlackList: "Чорний список",
        pets: "Тварини",
        doctor: "Лікар",
        lastVisitDate: "Дата останнього візиту",
        lastUpdatedBy: "Останнє оновлення від",
        tabs: {
          information: "Інформація",
        },
      },
    },
    pets: {
      name: "Тварина |||| Тварини",
      fields: {
        id: "id",
        name: "Ім'я",
        petType: "Тип тварини",
        photo: "Фото",
        age: "Вік",
        birtDate: "Дата народження",
        breed: "Порода",
        gender: "Стать",
        weight: "Вага",
      },
    },
    appointments: {
      name: "Призначення |||| Призначення",
      fields: {
        id: "id",
        client: "Клієнт",
        status: "Статус",
        date: "Дата",
        dateStart: "Початок",
        dateEnd: "Кінець",
        createdAt: "Коли створено",
        updatedAt: "Коли змінено",
      },
    },
    admin_logs: {
      name: "Активність |||| Активність",
      fields: {
        id: "id",
        createdAt: "Коли створено",
        event: "Подія",
      },
    },
  },
};
