import authProvider from "Application/Auth/authProvider";
import { useEffect, useState } from "react";
import { useNotify, Button, useRedirect } from "react-admin";
import CircularProgress from "@mui/material/CircularProgress";

import styles from "./style.module.css";
import { useMediaQuery } from "@material-ui/core";
import inMemoryJwt from "Application/Auth/inMemoryJwt";
import {ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY} from "../../utils/constants";

const MyLoginPage = (props) => {
  const { setUser } = props;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const notify = useNotify();

  const isMobile = useMediaQuery("(max-width:600px)");

  const [isLoading, setIsLoading] = useState(false);

  const searchParams = new URLSearchParams(window.location.hash.split("?")[1]);
  const redirectUrl = searchParams.get("redirectUrl");
  const token = searchParams.get(ACCESS_TOKEN_KEY);
  const refreshToken = searchParams.get(REFRESH_TOKEN_KEY);

  const redirect = useRedirect();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    authProvider
      .login({ username: email, password })
      .then(async () => {
        if (isMobile) {
          localStorage.setItem("RaStore.sidebar.open", false);
        }

        const userId = inMemoryJwt.getId();
        const userType = inMemoryJwt.getUserType();
        redirect(
          redirectUrl
            ? redirectUrl
            : userType !== "private"
            ? "/admins"
            : `/admins/${userId}/show/clients`
        );
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        notify("Invalid email or password", {
          type: "error",
        });
      });
  };

  useEffect(() => {
    setUser(null);
  }, [setUser]);

  useEffect(() => {
    if (token && refreshToken) {
      if (isMobile) {
        localStorage.setItem("RaStore.sidebar.open", false);
      }
      inMemoryJwt.setToken(token, refreshToken);
      redirect(redirectUrl ? redirectUrl : "/admins");
    }
    // eslint-disable-next-line
  }, [redirect, isMobile]);

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <h2 className={styles.title}>Log in to admin panel</h2>
        <div className={styles.inputItem}>
          <label>Email</label>
          <input
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
          />
        </div>

        <div className={styles.inputItem}>
          <label>Password</label>
          <input
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={styles.input}
          />
        </div>

        <Button
          className={styles.loginButton}
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            "SIGN IN"
          )}
        </Button>
      </form>
    </div>
  );
};

export default MyLoginPage;
