import { useTranslate } from "react-admin";

export const Status = [
  { id: "registered", name: "Registered" },
  { id: "paid", name: "Paid" },
];

export const useStatusChoices = () => {
  const translate = useTranslate();
  return [
    { id: "registered", name: translate("status.registered") },
    { id: "paid", name: translate("status.paid") },
  ];
};
