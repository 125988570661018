import * as React from "react";
import { AppBar, Logout, UserMenu } from "react-admin";
import { Avatar } from "@mui/material";

import "./style.css";
import Logo from "./Logo";
import { UserContext } from "./CustomLayout";
import { makePhotoSource } from "utils/constants";

const MyAppBar = (props) => {
  const { user } = React.useContext(UserContext);

  return (
    <AppBar
      {...props}
      className="header"
      userMenu={
        <UserMenu
          icon={
            <div className="user-dropdown">
              <Avatar
                src={
                  user?.photo
                    ? makePhotoSource(user?.photo.source) ||
                      "/default-avatar.png"
                    : undefined
                }
                alt={user?.firstName}
                sx={{ width: 32, height: 32 }}
              />
              <span>{`${user?.firstName || ""} ${user?.lastName || ""}`}</span>
            </div>
          }
          label={`${user?.firstName || ""} ${user?.lastName || ""}`}
        >
          <Logout />
        </UserMenu>
      }
    >
      <span style={{ flex: 2 }} className="mobile" />
      <div className="logo">
        <Logo className="logo-image" />
      </div>

      <span style={{ flex: 2 }} />
    </AppBar>
  );
};

export default MyAppBar;
