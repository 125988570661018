import polyglotI18nProvider from "ra-i18n-polyglot";
import { en } from "../../utils/i18n/en";
import { ua } from "../../utils/i18n/ua";
import { ru } from "../../utils/i18n/ru";

const translations = { en: en, ua: ua, ru: ru };
const i18nProvider = polyglotI18nProvider(
  (locale) => translations[locale],
  "en",
  [
    { locale: "en", name: "English" },
    { locale: "ua", name: "Українська" },
    { locale: "ru", name: "Русский" },
  ]
);

export default i18nProvider;
