import englishMessages from "ra-language-english";

export const en = {
  ...englishMessages,
  menu: {
    settings: {
      name: "Settings",
    },
    patients: {
      name: "Patients",
    },
    admins: {
      name: "Admins",
    },
    doctor: {
      name: "Doctor",
    },
    users: {
      name: "Patients",
    },
    doctorsOnDuty: {
      name: "Doctors on Duty",
    },
  },
  adminsForm: {
    fields: {
      firstName: "First Name",
      lastName: "Last Name",
      role: "Role",
      type: "Type",
      category: "Category",
      phone: "Phone",
      email: "Email",
      description: "Description",
      password: "Password",
      passwordRepeat: "Repeat Password",
      photo: "Photo",
    },
  },
  status: {
    registered: "Registered",
    paid: "Paid",
  },
  categories: {
    first: "First",
    highest: "Highest",
  },
  types: {
    public: "Public",
    private: "Private",
  },
  actions: {
    edit: "Edit",
  },
  buttons: {
    changePassword: "Change password",
    chat: "Chat",
  },
  dataGrid: {
    noResults: "No results found",
  },
  subscription: {
    statuses: {
      created: "Created",
      active: "Active",
      completed: "Expired",
      pending: "Pending",
      canceled: "Canceled",
      notAvailable: "No available",
      noSubscription: "No subscription",
    },
  },
  resources: {
    chat: {
      noMessages: "No messages",
      title: "Chat with user",
      inputPlaceholder: "Enter your message",
      sendMessage: "Send",
      sendFile: "Upload",
      edit: "Edit",
      save: "Save",
      remove: "Remove",
      cancel: "Cancel",
      patients: "Patients",
    },
    photoModal: {
      title: "Edit photo",
      saveButton: "Save",
      editButton: "Edit",
      removeButton: "Remove",
    },
    admins: {
      name: "Admin |||| Admins",
      fields: {
        id: "id",
        email: "Email",
        phone: "Phone",
        role: "Role",
        createdAt: "Created At",
        isOnDuty: "On Duty",
        createdBy: "Created by",
        firstName: "First Name",
        status: "Status",
        lastName: "Last Name",
        description: "Description",
        photo: "Photo",
        category: "Category",
      },
      tabs: {
        information: "Information",
        clients: "Patients",
        appointments: "Appointments",
        schedule: "Schedule",
        income: "Income",
        activity: "Activity",
      },
    },
    changePassword: {
      name: "Change password",
      fields: {
        password: "Password",
        passwordRepeat: "Password Repeat",
      },
    },
    doctors: {
      name: "doctor",
    },
    users: {
      name: "User |||| Users",
      fields: {
        id: "id",
        email: "Email",
        type: "Messenger type",
        status: "Status",
        phone: "Phone",
        createdAt: "Created At",
        updatedAt: "Updated At",
        createdBy: "Created by",
        name: "Name",
        subscription: "Subscription",
        isBlackList: "Black list",
        pets: "Pets",
        doctor: "Doctor",
        lastVisitDate: "Last visit date",
        lastUpdatedBy: "Last update by",
        tabs: {
          information: "Information",
        },
      },
    },
    pets: {
      name: "Pet |||| Pets",
      fields: {
        id: "id",
        name: "Name",
        petType: "Pet type",
        photo: "Photo",
        birtDate: "Birth date",
        breed: "Breed",
        gender: "Gender",
        weight: "Weight",
      },
    },
    appointments: {
      name: "Appointment |||| Appointments",
      fields: {
        id: "id",
        client: "Client",
        status: "Status",
        date: "Date",
        dateStart: "Start",
        dateEnd: "End",
        createdAt: "Created At",
        updatedAt: "Updated At",
      },
    },
    admin_logs: {
      name: "Activity |||| Activities",
      fields: {
        id: "id",
        createdAt: "Created At",
        event: "Event",
      },
    },
  },
};
