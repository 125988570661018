import { Admin, Resource, defaultTheme, CustomRoutes } from "react-admin";
import authProvider from "Application/Auth/authProvider";
import CustomLayout from "Component/Layouts/CustomLayout";
import dataProvider from "Application/Api/api";
import {
  AdminsList,
  AdminsEdit,
  AdminsCreate,
  AdminPasswordEdit,
} from "Pages/Admin/index";
import { ChatPage } from "Pages/Admin/Action/chatPage";
import { Route } from "react-router-dom";
import { UsersList, UsersEdit, UserShow } from "Pages/User/index";
import { AdminsShow } from "./Pages/Admin/Action/Show/show";
import { DoctorsOnDutyCreate } from "./Pages/Settings/index";
import { MyCalendar } from "./Pages/Calendar";
import { AppointmentEdit } from "./Pages/Appointment/Action/edit";
import i18nProvider from "./Application/I18nProvider/i18nProvider";
import MyLoginPage from "Pages/Login/Login";
import { useState } from "react";

const theme = {
  ...defaultTheme,
  palette: {
    primary: { main: "#4884EF" },
    secondary: { main: "#4884EF" },
  },
  sidebar: {
    width: 200,
    closedWidth: 54,
  },
  typography: {
    color: "white",
    background: "#4884EF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "900",
    lineHeight: "1rem",
    letterSpacing: "1.25px",
  },
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        margin: "dense",
        size: "small",
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: "3px solid transparent",
          "&.RaMenuItemLink-active": {
            borderLeft: "5px solid #4884EF",
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        sx: { width: "100%" },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          marginRight: "0px",
          marginLeft: "auto",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginDense: {
          width: "100%",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        //    icon: false
      },
      styleOverrides: {
        containedPrimary: {
          marginRight: "0px",
          marginLeft: "auto",
        },
        root: {
          "&.button-remove": {
            color: "red",
            marginTop: "4%",
          },
        },
      },
    },
  },
  overrides: {
    MuiToolbar: {
      regular: {
        background: "#fafafa",
      },
    },
    MuiTypography: {
      body: {
        fontWeight: 600,
      },
      body2: {
        fontWeight: 400,
        fontSize: "1.05rem",
        textAlign: "center",
      },
    },
    MuiInputBase: {
      input: {},
    },
    MuiFormLabel: {
      root: {},
    },
    MuiMenuItem: {
      root: {
        fontWeight: 600,
      },
    },
    MuiDialogContentText: {
      root: {},
    },
    RaLayout: {
      root: {
        '& button[aria-label="Remove"]': {
          color: "red",
          marginTop: "4%",
        },
        "& .fade-enter": {
          opacity: "1 !important",
          transform: "translateX(0) !important",
        },
      },
    },
    RaAppBar: {
      toolbar: {
        '& button[aria-label="Refresh"]': {
          display: "none",
        },
        '& button[title="Close menu"]': {
          display: "none",
        },
      },
    },
  },
};

function App() {
  const [user, setUser] = useState(null);

  return (
    <Admin
      theme={theme}
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={(props) => (
        <CustomLayout {...props} user={user} setUser={setUser} />
      )}
      i18nProvider={i18nProvider}
      loginPage={<MyLoginPage setUser={setUser} />}
    >
      <Resource
        name="admins"
        list={AdminsList}
        create={AdminsCreate}
        edit={AdminsEdit}
        show={AdminsShow}
      />
      ,
      <Resource name="admins/password" edit={AdminPasswordEdit} />,
      <Resource
        name="users"
        list={UsersList}
        edit={UsersEdit}
        show={UserShow}
      />
      <Resource name="doctor/on-duty" list={DoctorsOnDutyCreate} />
      <Resource name="calendar" list={MyCalendar} />
      <Resource name="doctor/appointment" edit={AppointmentEdit} />
      <CustomRoutes>
        <Route path="/chat/:id" element={<ChatPage />} />
      </CustomRoutes>
    </Admin>
  );
}

export default App;
