import React, { useCallback, useEffect, useState } from "react";
import { ShowMod, TabbedShowLayoutMod, TabMod } from "Component/Redesign/Show";
import ClientsTab from "./Tabs/clientsTab";
import ActivityTab from "./Tabs/activityTab";
import ScheduleTab from "./Tabs/schedule/scheduleTab";
import AppointmentsTab from "./Tabs/appintmantsTab";
import InformationTab from "./Tabs/informationTab";
import { usePermissions, useRedirect } from "react-admin";
import authService from "api/auth-service";
import inMemoryJwt from "Application/Auth/inMemoryJwt";

const PostShowActions = () => <div></div>;
export const AdminsShow = ({ permissions, ...props }) => {
  const { permissions: rightPermissions } = usePermissions();

  const [currentUser, setCurrentUser] = useState(null);

  const [isFirstRedirect, setIsFirstRedirect] = useState(true);

  const redirect = useRedirect();

  const userId = window.location.hash.split("#")[1].split("/")[2];

  const getUserById = useCallback(async () => {
    const response = await authService.getUserById(userId);
    setCurrentUser(response);
  }, [userId]);

  const userType = inMemoryJwt.getUserType();

  useEffect(() => {
    getUserById();
  }, [getUserById]);

  useEffect(() => {
    if (
      !window.location.hash.includes("clients") &&
      isFirstRedirect &&
      currentUser &&
      currentUser.role !== "ROLE_ADMIN"
    ) {
      redirect("clients");
      setIsFirstRedirect(false);
    }
  }, [redirect, currentUser, isFirstRedirect]);

  return (
    <ShowMod {...props} actions={<PostShowActions />}>
      <TabbedShowLayoutMod>
        {userType !== "private" && (
          <TabMod label="resources.admins.tabs.information">
            <InformationTab />
          </TabMod>
        )}

        {rightPermissions === "ROLE_DOCTOR" && (
          <TabMod label="resources.admins.tabs.clients" path="clients">
            <ClientsTab />
          </TabMod>
        )}

        {rightPermissions === "ROLE_DOCTOR" && userType !== "private" && (
          <TabMod label="resources.admins.tabs.appointments" path="appointment">
            <AppointmentsTab />
          </TabMod>
        )}

        {rightPermissions === "ROLE_DOCTOR" && userType !== "private" && (
          <TabMod label="resources.admins.tabs.schedule" path="schedule">
            <ScheduleTab />
          </TabMod>
        )}

        {rightPermissions === "ROLE_DOCTOR" && userType !== "private" && (
          <TabMod label="resources.admins.tabs.income" path="income">
            {/*<IncomeTab/>*/}
          </TabMod>
        )}

        {rightPermissions === "ROLE_ADMIN" &&
          userType !== "private" &&
          currentUser?.role !== "ROLE_ADMIN" && (
            <TabMod label="resources.admins.tabs.clients" path="clients">
              <ClientsTab currentUser={currentUser} />
            </TabMod>
          )}

        {rightPermissions === "ROLE_ADMIN" &&
          userType !== "private" &&
          currentUser?.role !== "ROLE_ADMIN" && (
            <TabMod
              label="resources.admins.tabs.appointments"
              path="appointment"
            >
              <AppointmentsTab />
            </TabMod>
          )}

        {rightPermissions === "ROLE_ADMIN" &&
          currentUser?.role !== "ROLE_ADMIN" && (
            <TabMod label="resources.admins.tabs.schedule" path="schedule">
              <ScheduleTab />
            </TabMod>
          )}

        {rightPermissions === "ROLE_ADMIN" &&
          currentUser?.role !== "ROLE_ADMIN" && (
            <TabMod label="resources.admins.tabs.income" path="income">
              {/*<IncomeTab/>*/}
            </TabMod>
          )}

        {rightPermissions !== "ROLE_DOCTOR" && (
          <TabMod label="resources.admins.tabs.activity" path="activity">
            <ActivityTab />
          </TabMod>
        )}
      </TabbedShowLayoutMod>
    </ShowMod>
  );
};
