export const ACCESS_TOKEN_KEY = "token";
export const REFRESH_TOKEN_KEY = "refreshToken";

export const MINUTE_IN_MILLISECONDS = 60000

export const apiRoutes = {
  login: "/login",
  logout: "/logout",
  schedule: "/schedule",
  chat: "/chat",
  message: "/message",
  sendMesage: "/doctor/chat",
  users: "/users",
  file: "/file",
  me: "/me",
  pet: "/pet",
};

export const routes = {
  login: "/login",
};

export const makePhotoSource = (source) => {
  return `${
    process.env.REACT_APP_AWS_S3_BUCKET_URL ??
    "https://upet-dev-s3-bucket.s3.eu-west-3.amazonaws.com"
  }/${source}`;
};
