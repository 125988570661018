import decodeJwt from "jwt-decode";
import {ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY} from "../../utils/constants";

const apiUrl = process.env.REACT_APP_DOMAIN + "/api";

const inMemoryJWTManager = () => {
  let logoutEventName = "ra-logout";
  let checkAuthEndpoint = `${apiUrl}/admin/login`;
  let refreshEndpoint = `${apiUrl}/admin/token/refresh`;
  let isRefreshing = null;
  let refreshTimeOutId;
  let delay = 900;

  // This listener allows to disconnect another session of react-admin started in another tab
  window.addEventListener("storage", (event) => {
    if (event.key === logoutEventName) {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      abordRefreshToken();
    }
  });

  // This countdown feature is used to renew the JWT in a way that is transparent to the user.
  // before it's no longer valid
  const setRefreshToken = () => {
    refreshTimeOutId = window.setTimeout(checkAuth, delay * 1000 - 5000); // Validity period of the token in seconds, minus 5 seconds
  };

  const abordRefreshToken = () => {
    if (refreshTimeOutId) {
      window.clearTimeout(refreshTimeOutId);
    }
  };

  const waitForTokenRefresh = () => {
    if (!isRefreshing) {
      return Promise.resolve();
    }

    return isRefreshing.then(() => {
      isRefreshing = null;
      return true;
    });
  };

  const checkAuth = () => {
    const headers = new Headers({});
    headers.set("Authorization", `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`);
    headers.set("Content-Type", "application/json");
    const request = new Request(checkAuthEndpoint, {
      method: "GET",
      headers: headers,
    });

    return fetch(request).then((response) => {
      if (response.status === 200) {
        setRefreshToken();
        return true;
      }

      getRefreshedToken();
      return true;
    });
  };

  const getRefreshedToken = () => {
    if (isRefreshing) return
    const tokenFromStorage = localStorage.getItem(ACCESS_TOKEN_KEY)

    if (!tokenFromStorage) {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      window.location.href = "/#/login";
      return
    }

    const refreshTokenFromStorage = localStorage.getItem(REFRESH_TOKEN_KEY)
    if (!refreshTokenFromStorage) return
    const request = new Request(refreshEndpoint, {
      method: "POST",
      body: JSON.stringify({
        refresh_token: refreshTokenFromStorage,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });

    isRefreshing = fetch(request)
      .then((response) => {
        if (response.status !== 200) {
          ereaseToken();
          global.console.log("Token renewal failure");
          return { data: { token: null } };
        }

        return response.json();
      })
      .then((data) => {
        if (data.status) {
          //  const decodedToken = decodeJwt(data.data.token);
        }
        return data;
      })
      .then(({ data }) => {
        if (data.token) {
          localStorage.removeItem(ACCESS_TOKEN_KEY);
          localStorage.removeItem(REFRESH_TOKEN_KEY);
          setToken(data.token, data.refresh_token || data.refreshToken, 10);
          return true;
        }

        ereaseToken();
        return false;
      });

    return isRefreshing;
  };

  const decodeToken = () => {
    try {
      const token = getToken();
      return token ? decodeJwt(token) : null;
    } catch (e) {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      window.location.href = "/#/login";
    }
  };

  const hasPermissions = () => {
    const decodedToken = decodeToken();
    if (!decodedToken) {
      return false;
    }

    //return HasPermissions([CONTENT, ADMISSION, PARTNERSHIP], decodedToken.roles[0]);
    return true;
  };

  const getRoles = () => {
    const decodedToken = decodeToken();
    return decodedToken ? decodedToken.roles[0] : false;
  };

  const getId = () => {
    const decodedToken = decodeToken();
    return decodedToken ? decodedToken.userId : false;
  };

  const getUserType = () => {
    const decodedToken = decodeToken();
    return decodedToken ? decodedToken.type : '';
  };

  const getName = () => {
    const decodedToken = decodeToken();
    return decodedToken ? decodedToken.username : false;
  };

  const getEmail = () => {
    const decodedToken = decodeToken();
    return decodedToken ? decodedToken.email : false;
  };

  const isExpired = () => {
    const decodedToken = decodeToken();
    const currentTime = new Date().getTime() / 1000;

    return currentTime > decodedToken.exp;
  };

  const getToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

  const setToken = (token, refreshToken) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    setRefreshToken();
    return true;
  };

  const ereaseToken = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    abordRefreshToken();
    localStorage.setItem(logoutEventName, Date.now());
    return true;
  };

  const setLogoutEventName = (name) => (logoutEventName = name);

  return {
    ereaseToken,
    getToken,
    decodeToken,
    getRoles,
    getId,
    getUserType,
    getName,
    getEmail,
    setLogoutEventName,
    setToken,
    waitForTokenRefresh,
    hasPermissions,
    getRefreshedToken,
    isExpired,
  };
};

export default inMemoryJWTManager();
